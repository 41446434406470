//////////////////////////////////
//// COMMON STYLES ////
/////////////////////////////////
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    *::selection {
        @apply bg-primary text-white;
    }

    body {
        @apply font-base text-title bg-white;
    }

    button {
        @apply transition-all duration-300;
    }





    // Search select styles here
    // .custom-search-select {
    //     .css-26l3qy-menu {
    //         @apply z-20;
    //     }

    //     .css-1s2u09g-control,
    //     .css-1pahdxg-control {
    //         min-height   : 32px;
    //         height       : 32px;
    //         border-radius: 6px;
    //         font-size    : 13px;
    //         width        : 100%;
    //         box-shadow   : none;
    //         padding-left : 20px;
    //         @apply border-borderColor-200;

    //         &:hover {
    //             @apply border-borderColor-200;
    //             box-shadow: none;
    //         }
    //     }

    //     .css-14el2xx-placeholder {
    //         @apply text-greyColor-900;
    //     }

    //     .css-1hb7zxy-IndicatorsContainer {
    //         display: none;
    //     }

    //     .css-4ljt47-MenuList {
    //         font-size: 12px;
    //         padding: 0;
    //     }
    //     .css-4ljt47-MenuList >div{
    //         padding: 2px 8px
    //     }

    //     &.for-add-user {

    //         // for update the select height as 50px and width as 334px
    //         .css-1s2u09g-control,
    //         .css-1pahdxg-control {
    //             min-height  : 50px;
    //             height      : 50px;
    //             width       : 334px;
    //             padding-left: 18px;
    //         }
    //     }

    //     @media screen and (min-width: 640px) {

    //         .css-1s2u09g-control,
    //         .css-1pahdxg-control {
    //             width: 150px;
    //         }
    //     }

    //     @media screen and (min-width: 992px) {

    //         .css-1s2u09g-control,
    //         .css-1pahdxg-control {
    //             width: 184px;
    //         }
    //     }
    // }

    .all-button {
        &:before {
            content: '';
            width: 2px;
            height: 22px;
            position: absolute;
            background: #E5EAEF;
            left: -15px;
        }
    }

    // react select custom style
    .custom-search-select {

        &.no-focus-shadow {
            .dropdownSelect__control {
                &.dropdownSelect__control--is-focused {}
            }
        }

        .dropdownSelect__multiValue {}

        .dropdownSelect__input-container {
            margin: 0;
            margin-bottom: 0;

        }

        .dropdownSelect__option {
            font-size: 13px;
            padding: 6px;
        }

        .dropdownSelect__control {
            height: rem(46,1);
            background: transparent;
            cursor: pointer;
            border: 0;
            padding: 6px 8px;

            &.dropdownSelect__control--is-focused {
                &:hover {}
            }

            &:hover {}

            .dropdownSelect__multi-value {
                background: #e4efff;
                border-radius: 4px;
                font-size: 13px;
                overflow: hidden;
            }

            .dropdownSelect__multi-value__remove {
                background: #1769e5;

                svg {
                    fill: #fff;
                }

                &:hover {
                    opacity: 0.6 !important;
                }
            }
        }

        .dropdownSelect__menu {
            z-index: 2;
            left: 0;
        }

        .dropdownSelect__value-container {}

        .dropdownSelect__placeholder {}

        .dropdownSelect__indicator-separator {
            display: none;
        }

        .dropdownSelect__clear-indicator {
            padding-right: 0;
        }

        .dropdownSelect__dropdown-indicator {
            background: none;
            padding-left: 2px;
            padding-right: 5px;

            svg {
                // display: none;
            }
        }

        .dropdownSelect__option--is-selected {
            font-size: 13px;
        }

        .dropdownSelect__option {
            cursor: pointer;
            padding: 3px 10px;
            font-size: 13px;

            &:hover {
                padding: 3px 10px;
                font-size: 13px;
            }
        }

        .dropdownSelect__menu-list {
            font-size: 13px;
        }

        &.no-arrow-down {
            &:before {
                display: none;
            }

            .dropdownSelect__dropdown-indicator {
                display: none;
            }
        }
    }


    .medium-selectbox {
        height: 40px;

        &.no-focus-shadow {
            .dropdownSelect__control {
                font-size: 14px;
                border: 0;

                &.dropdownSelect__control--is-focused {
                    //@include box-shadow(none); 
                }
            }
        }

        .dropdownSelect__single-value {
            color: #B6BABD;
        }

        .dropdownSelect__input-container {
            margin: 0;
            margin-bottom: 0;
        }

        .dropdownSelect__option {
            font-size: 13px;
            padding: 6px;
        }

        .dropdownSelect__control {
            font-size: 14px;
            border: 0;
            background: transparent;
            cursor: pointer;

            &.dropdownSelect__control--is-focused {
                &:hover {}
            }

            &:hover {}
        }

        .dropdownSelect__menu {
            z-index: 2;
            left: 0;
        }

        .dropdownSelect__value-container {}

        .dropdownSelect__placeholder {
            color: #B6BABD;
        }

        .dropdownSelect__indicator-separator {
            display: none;
        }

        .dropdownSelect__clear-indicator {
            padding-right: 0;
        }

        .dropdownSelect__dropdown-indicator {
            background: none;
            padding-left: 2px;
            padding-right: 5px;

            svg {
                // display: none;
            }
        }

        .dropdownSelect__option--is-selected {
            font-size: 13px;
        }

        .dropdownSelect__option {
            cursor: pointer;
            padding: 3px 10px;
            font-size: 13px;

            &:hover {
                padding: 3px 10px;
                font-size: 13px;
            }
        }

        .dropdownSelect__menu-list {
            font-size: 13px;
        }

        .dropdownSelect__multi-value {
            background: #e4efff;
            border-radius: 4px;
            font-size: 13px;
            overflow: hidden;
        }

        .dropdownSelect__multi-value__remove {
            background: #1769e5;

            svg {
                fill: #fff;
            }

            &:hover {
                opacity: 0.6 !important;
            }
        }

        &.no-arrow-down {
            &:before {
                display: none;
            }

            .dropdownSelect__dropdown-indicator {
                display: none;
            }
        }

    }


    .selectbox-svg-hide {
        .dropdownSelect__dropdown-indicator {
            svg {
                display: none;
            }
        }
    }






    .datetime-reset-button {
        visibility: hidden;
    }

    .custom-search-create {
        padding-left: 0;
        padding-right: 0;

        // .css-1s2u09g-control,
        // .css-1pahdxg-control {
        //     width  : 100%;
        //     padding: 0;
        //     border : 0;
        // }

        // .css-319lph-ValueContainer {
        //     padding  : 8px 16px;
        //     font-size: 16px;
        // }

        // .css-qc6sy-singleValue {           
        //     font-size: 16px;
        // }

        // .css-1s2u09g-control,
        // .css-1pahdxg-control {
        //     height: auto;
        // }

        .css-1rhbuit-multiValue {
            //@apply bg-primary-navyBlue bg-opacity-5;
        }
    }

    // Custom scrollbar styles
    .custom-scroll-wrapper {
        overflow-y: scroll !important;
        scrollbar-color: rgba(0, 0, 0, 0.2) white;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
        }
    }

    // Date picker styles
    .custom-datepicker-wrapper {
        color: #B6BABD;

        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                @apply z-10 ring-transparent ring-0;
            }

            input {
                padding: 0 16px;
                @apply w-full h-full rounded-md bg-transparent placeholder-secondary-grey-300;
            }
        }

        .react-datepicker-popper {
            @apply z-20;
        }
    }

    .set-activity-btn {

        button:disabled,
        button[disabled] {
            background-color: #1769e5;
            opacity: 0.3;
            cursor: not-allowed;
            color: #fff;
        }

    }



    // Custom pagination
    .custom-pagination-wrapper {
        .custom-nav {
            &.disabled a {
                background: transparent;
                cursor: not-allowed;
            }

            &.disabled {
                cursor: not-allowed;
                background: white;
                @apply text-title;
            }
        }
    }

    .nav-active {
        position: relative;

        &::after {
            position: absolute;
        }
    }

    .visible .ql-toolbar.ql-snow {
        border: none !important;
        padding: 0;
    }

    .visible .ql-container.ql-snow {
        border: none !important;
    }

    .visible .ql-editor {
        padding: 12px 0;
    }

    .visible .ql-snow .ql-picker-label {
        padding-left: 0;
    }

    .availability-popup {
        .close-btn {
            right: -40px;
        }
    }

    .ql-container.ql-snow {
        border: 0;
    }

    .loader {
        border: 2px solid #fff;
        border-radius: 50%;
        border-top: 2px solid #5095fe;
        width: 12px;
        height: 12px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
    }

    .loader-secondary {
        border: 2px solid #1768E5;
        border-radius: 50%;
        border-top: 2px solid #85b5ff;
        width: 12px;
        height: 12px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }



    .common-editor {

        .ql-toolbar.ql-snow {
            border: none;
        }

        .ql-container.ql-snow {
            border: none;
        }

        .ql-snow .ql-picker-label {
            padding-left: 5px;
        }
    }

    $pie-width : 90px;

    .pie-parent {
        width: $pie-width;
        height: $pie-width;
        background-color: #c3c1c1;
    }

    .pie-mask {
        top: 12%;
        right: 12%;
        bottom: 12%;
        left: 12%;
        background: #fff;
        //   display:flex;
        //   align-items:center;
        //   justify-content:center;
    }

    @property --p {
        syntax: '<number>';
        inherits: true;
        initial-value: 0;
    }

    .pie {
        --p: 20;
        --b: 12px;
        --c: #1DBC6C;
        --w: 90px;
        width: var(--w);
        aspect-ratio: 1;
        position: relative;
        display: inline-grid;
        place-content: center;
        font-size: 25px;
        font-weight: bold;
        font-family: sans-serif;
    }

    .pie:before,
    .pie:after {
        content: "";
        position: absolute;
        border-radius: 50%;
    }

    .pie:before {
        inset: 0;
        background:
            radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
            conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
        mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    }

    .pie:after {
        inset: calc(50% - var(--b)/2);
        background: var(--c);
        transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
    }

    .animate {
        animation: p 1s .5s both;
    }

    .no-round:before {
        background-size: 0 0, auto;
    }

    .no-round:after {
        content: none;
    }

    @keyframes p {
        from {
            --p: 0
        }
    }

    .popup-close-btn {
        right: -40px;
    }

    .pie {
        .booked-item {
            span {}
        }
    }







}

.slick-slider {
    .slick-prev {
        left: -11px;
    }

    .slick-next {
        right: -11px;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        top: 50%;
        margin-top: -11px;
        z-index: 1;
        background: #fff;
        font-size: 0;
        border: 2px solid #e5e5e5;
        transform: scale(var(--ggs, 1));
        border-radius: 4px;

        &:after,
        &:before {
            transition: all 0.3s linear;
        }

        &:hover {
            &:after {
                border-color: blue;
            }

            &:before {
                background: blue;
            }
        }
    }

    .slick-prev::after,
    .slick-prev::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        left: 4px
    }

    .slick-prev::after {
        width: 6px;
        height: 6px;
        border-bottom: 2px solid #e5e5e5;
        border-left: 2px solid #e5e5e5;
        transform: rotate(45deg);
        bottom: 6px
    }

    .slick-prev::before {
        width: 10px;
        height: 2px;
        bottom: 8px;
        background: #e5e5e5
    }

    .slick-next {
        // box-sizing: border-box;
        // position: relative;
        // display: block;
        // width: 22px;
        // height: 22px;
        // border: 2px solid;
        // transform: scale(var(--ggs,1));
        // border-radius: 4px
    }

    .slick-next::after,
    .slick-next::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        right: 4px
    }

    .slick-next::after {
        width: 6px;
        height: 6px;
        border-top: 2px solid #e5e5e5;
        border-right: 2px solid #e5e5e5;
        transform: rotate(45deg);
        bottom: 6px
    }

    .slick-next::before {
        width: 10px;
        height: 2px;
        bottom: 8px;
        background: #e5e5e5
    }
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:after,
.slick-next.slick-disabled:after {
    opacity: 1 !important;
}

// .slick-prev.slick-disabled{
//     opacity:0 !important;
// }
.plus-icon {
    width: 14px;
    height: 14px;
    position: relative;
    display: block;
    margin-top: 2px;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.3s linear;
    }

    &:after {
        width: 100%;
        height: 2px;
        background: rgba(0, 0, 0, 0.4);
        top: 50%;
        margin-top: -1px;
    }

    &:before {
        height: 100%;
        width: 2px;
        background: rgba(0, 0, 0, 0.4);
        left: 50%;
        margin-left: -1px;
    }

    &:hover {

        &:after,
        &:before {
            background: blue;
        }
    }
}

.main-menu {
    a {
        color: black;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .active {
        opacity: 1;
    }
}

// loader
.init-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid #1768e5;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: #1768e5;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px #1768e5;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);

    &:before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: 100%;
        height: 100%;
        border: 3px solid transparent;
        border-top: 1px solid #1768e5;
        border-right: 1px solid #1768e5;
        border-radius: 50%;
        animation: animateC 2s linear infinite;
    }

    span {
        display: block;
        position: absolute;
        top: calc(50% - 2px);
        left: 50%;
        width: 50%;
        height: 4px;
        background: transparent;
        transform-origin: left;
        animation: animate 2s linear infinite;
    }

    span:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #1768e5;
        top: -6px;
        right: -8px;
        box-shadow: 0 0 20px #1768e5;
    }

    @keyframes animateC {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes animate {
        0% {
            transform: rotate(45deg);
        }

        100% {
            transform: rotate(405deg);
        }
    }
}


.myprofile-wrapper {
    h3 {
        color: #000;
    }

    h6 {
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin-bottom: 15px;
    }

    ul {
        li {
            font-size: 14px;
            color: #5E6163;
            margin-bottom: 8px;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }
}

.Toastify {
    .Toastify__close-button {
        display: none;
    }
}

.all-projects {
    background-color: #4361EE;
}

.project-item-block {
    .icon {
        width: 60px;
        height: 60px;
        background: rgba(255, 255, 255, 0.25);
        ;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h5 {
        color: black;
        font-weight: 400;
        font-size: 14px;
    }

    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: normal;
    }
}

.active-projects {
    background-color: #3A86FF;
}

.hold-projects {
    background-color: #00A6FB;
}

.completed-projects {
    background-color: #4CC9F0;
}

.selected-requirement-highlight{
    margin-right: -1px;padding-left: 20px;
    z-index: 103;
    opacity: 1;
    background-color: #fff;
}