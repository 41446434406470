.flex{
  display:flex;
}

.list-group-item{
  border:1px solid rgb(180, 22, 22);
}
#example4-right{
  background:rgb(51, 51, 110);
  padding:10px;
}
#example5-right{ min-height:60px; background:rgb(191, 92, 92); width:100%; margin:10px 0;}