.react-calendar {
  width: fit-content;
  border: none;
}

.react-calendar__tile {
  padding: 10px 0;
  /* height: 40px; */
}

.react-calendar__tile--now {
  background: none;
  font-weight: bold;
  color: inherit;
}

.react-calendar__tile--active {
  background: none;
  color: inherit;
  font-weight: bold;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}

.react-calendar__tile--hasActive {
  background: none;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: none;
}

.react-calendar__tile--now.react-calendar__tile--active {
  background: none;
}

.react-calendar__tile--hasActive.react-calendar__tile--now {
  background: none;
}

.bg-blue-500 {
  background-color: #A0DEFF !important;
  color: #fff;
  border-radius: 50%;
}

.react-time-picker__wrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.react-time-picker__inputGroup__input {
  width: 16px;
  text-align: center;
  border: none;
  focus: outline-none;
}

.react-time-picker__inputGroup__divider {
  margin: 0;
}

.react-time-picker__inputGroup {
  display: flex;
  align-items: center;
}

.react-time-picker__inputGroup__leadingZero {
  display: none;
}

.flexible-modal-drag-area {
  display: none;
}

.react-calendar__navigation button {
  min-width: 27px;
}
.react-calendar__navigation {
  margin-bottom: 0em;
}
